(function(){
    "use strict";
    //Normalerweise sollte dieses Script "deferred" geladen werden, also ohnehin erst nach Rendern des DOM ausgeführt werden.
    //Dadurch kann es aber z.B. beim Non-Deferred Laden von Inline-Script wie dem für TinyMCE dazu kommen, dass diese Methode sich erst fürs DOMContentLoaded-
    //Event registriert, wenn die Inline-Scripte (die erst später ausgeführt werden sollten) bereits dort registriert sind – also früher ausgeführt werden.
    //Daher soll der wesentliche Code, der für Inline-Scripte wie TinyMCE wichtig sein kann, hier _sofort_ ausgeführt werden.
    //Wenn ein deferred Loading absolut sicher ist, könnte _alles_ sofort ausgeführt werden. Für alle Fälle aber soll einfach der Rest, der wirklich erst
    //auf ein fertiges DOM aufsetzt und nicht schon gefahrlos früher ausgeführt werden darf, dennoch in einen DomContentLoaded-Eventlistener aufgenommen werden.
    //Also mehrstufiger Prozess: 
    //1. sofort: Was nicht aufs DOM aufbaut, prüfe, ob der Darkmode aktiviert werden soll, und setze Flag an Window-Objekt
    //2. Nun sollten (wenn dieses Script korrekt deferred wurde oder nach dem Inline-Script eingebunden wurde) erstmal Inline-Scripte wie TinyMCE
    //   geladen werden. TinyMCE sollte dann dieses Flag für sein eigenes Theme berücksichtigen und auch bei diesem gesetzten Flag dem Content ggf. die Klasse disableDarkMode hinzufügen.
    //   Letzteres weil: Bisher kopierte TinyMCE nur einfach die Classlist vom Haupt-Body auch in die IFrame-Bodies. Aber da fehlt ja nun in diesem Standard-Szenario
    //   noch die disableDarkmode-Klasse.
    //3. Eventlistener nach DOMContentLoaded: Aktiviert nun wirklich den Darkmode für das Hauptdokument und fügt ggf. die disableDarkMode-Klasse zum Body hinzu.
    //   Sorgt auch für eine Übertragung dieser Klasse in die IFrames.
    //   Aber es kann sein, dass die TinyMCE-IFrames noch nicht fertig geladen sind und das damit wirkungslos bleibt. Genau deshalb sollte der TinyMCE die
    //   disableDarkMode-Klasse ggf. selbst bereits hinzugefügt haben im Fall, dass das window-Flag gesetzt ist, siehe 2.
    

    const className="disableDarkMode";
    const TIMEOUT = 31 * 24 * 60 * 60000; //31 Tage Gültigkeitsdauer für LocalStorage-Eintrag in Millisekunden.
    
    function initFromStorage1() {
        var timestampString = localStorage.getItem("suppressDarkMode");
        if (timestampString !== null) {
            //Timeout überschritten? Dann löschen, sonst reaktivieren.
            const asInt = parseInt(timestampString);
            if (asInt + TIMEOUT < Date.now())
                localStorage.removeItem("suppressDarkMode");
            else 
                window.deFernUniHagenOuesDisableDarkmode = true;
        }
    }
    
    initFromStorage1();

    document.addEventListener("DOMContentLoaded", function() {

        function initFromStorage2() {
            if (window.deFernUniHagenOuesDisableDarkmode)
                disable(); //also refreshes timestamp
        }

        function isDisabled() {
            return document.body.classList.contains(className);
        }

        function disable() {
            document.body.classList.add(className);
            localStorage.setItem("suppressDarkMode", Date.now()); //Speichere Zeitpunkt
            updateIFrames(true);
        }

        function enable() {
            document.body.classList.remove(className);
            localStorage.removeItem("suppressDarkMode");
            updateIFrames(false);
        }


        /*
         * Trägt die disableDarkMode-Klasse ggf. auch in die Body-Tags aller Same-Origin-iframes
         * ein (bzw. entfernt sie), damit auch die die DarkMode-Einstellung "erben".
         * docNode kann undefined bleiben (nur ein Argument angeben), wird nur intern für Rekursion genutzt,
         * um IFrames innerhalb von IFrames ebenfalls abarbeiten zu können. Die kommen durchaus vor:
         * IBK-IFrames enthalten wiederum TinyMCE-IFrames!
         */
        function updateIFrames(addClass, docNode) {
            const d = docNode ? docNode : document;
            const iframes = d.getElementsByTagName("iframe");
            for(let i=0; i < iframes.length; i++) {
                const f = iframes[i];
                if (f.contentDocument.domain === document.domain) { //Same Origin
                    if (addClass)
                        f.contentDocument.body.classList.add(className);
                    else
                        f.contentDocument.body.classList.remove(className);
                }
                //Rekursion, s.o.:
                updateIFrames(addClass, f.contentDocument);
            }
        }

        const mm = window.matchMedia("(prefers-color-scheme: dark)");

        document.deFeuIsDarkMode = function() {
            return mm.matches && ! document.body.classList.contains(className);
        };

        /**
         * Es können getrennte Stylesheets für Light- und DarkMode per link-Tags eingebunden werden.
         * Das DarkMode-Stylesheet bekommt rel="stylesheet alternate" eingetragen. Wenn außerdem beide
         * Link-Tags jeweils ein class-Attribut bekommen (class="darkMode" bzw. class="lightMode"),
         * dann sorgt dieses Script dafür, dass die "alternate"-Kennzeichnung beim Wechsel zwischen Dark Mode
         * und Light Mode getauscht wird. Dabei wird neben der globalen Präferenz auch die lokale Checkbox-/Cookie-
         * Präferenz berücksichtigt.
         */
        function updateAlternateCssLinks() {
            const dark = document.querySelectorAll("link[class='darkMode']");
            const light = document.querySelectorAll("link[class='lightMode']");
            const targetDark = document.deFeuIsDarkMode();
            for (let i=0; i<dark.length; i++) {
                dark[i].rel = 'stylesheet' + (targetDark ? '' : ' alternate');
            }
            for (let i=0; i<light.length; i++) {
                light[i].rel = 'stylesheet' + (targetDark ? ' alternate' : '');
            }
        }

        //BEGIN

        initFromStorage2();
        updateAlternateCssLinks();
        window.matchMedia("(prefers-color-scheme: dark)").addListener(updateAlternateCssLinks);
        //Der Block mit dem Toggle ist im HTML hart ausgeblendet und wird nur bei aktivem Skript sichtbar geschaltet.
        //Auf diese Weise fehlt er ganz in Seiten ohne aktives JS.
        //In bestimmten Templates (z.B. IFrame) kann er auch fehlen (dann gibt die Suche null zurück). Eine per Cookie
        //vorliegende Einstellung soll dann dennoch angewandt werden (s.o.), aber der Code zum Einblenden oder Eventhandler-Registrieren
        //kann dann nicht arbeiten
        const dmSettings = document.getElementById("darkModeSettings");
        if (dmSettings) {
            dmSettings.removeAttribute("style");

            const chk = document.getElementById("chkSuppressDarkMode");
            if (isDisabled() /*after init, by cookie*/) {
                chk.checked = true;
            }
            chk.addEventListener("click", function() {
                if (chk.checked) {
                    disable();
                } else {
                    enable();
                }
                updateAlternateCssLinks();
            });
        }

    });
    
})();